import React from 'react';
import Navbar from '../components/Navbar';
import Hero from '../components/Hero';
import AboutUs from '../components/AboutUs';
import Projects from '../components/Projects';
import Footer from '../components/Footer'

const App: React.FC = () => {
  return (
    <div className="App">
      <Navbar />
      <Hero />
      <AboutUs />
      <Projects />
      <main>
        {/* Other content */}
      </main>
      <Footer />
    </div>
  );
};

export default App;
