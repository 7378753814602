import React from 'react';
import { FaGithub } from 'react-icons/fa'; // Importing GitHub icon from FontAwesome

const Footer: React.FC = () => {
  return (
    <footer className="footer">
      <div className="footer-icons">
        {/* X Logo as an Image */}
        <a href="https://x.com/czlabs_io" target="_blank" rel="noopener noreferrer">
          <img src="/x-logo.png" alt="X Logo" className="x-logo-footer" />
        </a>

        {/* Book Now Button */}
        <a href="https://calendly.com/contact-czlabs/30min" target="_blank" rel="noopener noreferrer">
          <button className="button-test-1">Book Now</button>
        </a>

        {/* GitHub Icon */}
        <a href="https://github.com/czlabsIO" target="_blank" rel="noopener noreferrer">
          <FaGithub className="text-2xl text-white hover:text-gray-400" />
        </a>
      </div>
    </footer>
  );
};

export default Footer;
