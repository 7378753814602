import React, { useEffect, useRef } from 'react';
import { Canvas, useFrame } from '@react-three/fiber';
import { OrbitControls, useFBX } from '@react-three/drei';
import * as THREE from 'three'; // Import THREE.js to access material properties

const Hero: React.FC = () => {
  const complexRef = useRef<HTMLSpanElement | null>(null);

  useEffect(() => {
    const currentComplex = complexRef.current;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && currentComplex) {
            currentComplex.classList.add('fade-in');
          }
        });
      },
      { threshold: 0.5 }
    );

    if (currentComplex) {
      observer.observe(currentComplex);
    }

    return () => {
      if (currentComplex) {
        observer.unobserve(currentComplex);
      }
    };
  }, []);

  // Component to load and spin the FBX model
  const FBXModel = () => {
    const fbx = useFBX('/sphere-2.fbx');  // Adjust the path to your .fbx model
    const fbxRef = useRef<any>();

    // Set the material color when the model is loaded
    useEffect(() => {
      if (fbx && fbxRef.current) {
        fbx.traverse((child: any) => {
          if (child.isMesh) {
            // Create a new material with the desired color
            child.material = new THREE.MeshStandardMaterial({
              color: new THREE.Color(0x464646), // Brighter gray color for better visibility
              // Less transparency, higher opacity
                             // Enable wireframe for mesh effect
              // Add a slight emissive glow to make it more visible
            });
          }
        });
      }
    }, [fbx]);

    // Rotate the model on the X-axis
    useFrame(() => {
      if (fbxRef.current) {
        fbxRef.current.rotation.y += 0.002; // Adjust the speed here
      }
    });

    return <primitive ref={fbxRef} object={fbx} scale={0.019} />;
  };

  return (
    <section
      id="hero-section"
      className="hero-section bg-cover bg-center h-screen flex flex-col items-center justify-center"
      style={{ backgroundImage: `url('/abstract-lights-bg.svg')`, position: 'relative' }}
    >
      <h1 className="hero-title">
        BESPOKE SOLUTIONS TO <br />
        <span className="highlight-complex" ref={complexRef}>COMPLEX</span> <span className="highlight-problems">PROBLEMS</span>
      </h1>
      <p className="hero-paragraph">
        At czlabs, we create innovative solutions across all blockchain ecosystems, seamlessly merging blockchain technology with artificial intelligence to unlock limitless possibilities. If you can envision it, we can build it.
      </p>

      {/* 3D Model Canvas */}
      <div style={{ width: '360px', height: '360px', position: 'absolute', top: '200px' }}>
        <Canvas>
          <ambientLight intensity={0.5} />
          <directionalLight position={[0, 5, 5]} />
          <OrbitControls enableZoom={false} /> {/* Disabling zoom for fixed view */}
          <FBXModel />
        </Canvas>
      </div>
    </section>
  );
};

export default Hero;
