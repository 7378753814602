import React, { useEffect, useRef, useState } from 'react';

const Projects: React.FC = () => {
  const titleRef = useRef<HTMLHeadingElement | null>(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [cardsPerPage, setCardsPerPage] = useState(3);
  
  const projectCards = [
    { title: 'Spiderswap', image: '/spider-test.png', description: 'Solana Aggregation & Infrastructure Engine', link: 'https://x.com/spiderswap' },
    { title: 'Solana Foundation', image: '/solana-found.png', description: 'Grant for work in AI integration in DApps', link: 'https://x.com/SolanaFndn'},
    { title: 'Cardboard Citizens', image: '/card-board.png', description: 'IP, pNFT, Community', link: 'https://x.com/cardboard_z' },
    { title: 'Solana Cash', image: '/solana-cash.png', description: 'Based on Epoch 640. SPL Token', link: 'https://x.com/SolanaCashFdn'},
    { title: 'LowLifeForms', image: '/lowlife-forms.png', description: 'IP, Blockchain Gaming, Community', link: 'https://x.com/LowLifeForms'},
    { title: 'The Keepers', image: '/the-keepers.png', description: 'IP, Community, AI PFP Staking Provider', link: 'https://x.com/TheKeepersNFT' },
    { title: 'ABC Runes', image: '/abc-runes.png', description: 'Analytics & Marketplace platform for Runes', link: 'https://x.com/ABCRunes' },
    { title: 'Nebula', image: '/nebula.png', description: 'Discord Server Auditing and setup service', link: 'https://x.com/nebulaguardio' },
    { title: 'Bit Freaks', image: '/bit-freaks.png', description: 'Blockchain Gaming Engine - Web & Mobile', link: 'https://x.com/BitFreaksNFT' },
  ];
  

  useEffect(() => {
    const updateCardsPerPage = () => {
      if (window.innerWidth >= 1024) {
        setCardsPerPage(3);
      } else if (window.innerWidth >= 768) {
        setCardsPerPage(2);
      } else {
        setCardsPerPage(1);
      }
    };

    updateCardsPerPage();
    window.addEventListener('resize', updateCardsPerPage);
    return () => window.removeEventListener('resize', updateCardsPerPage);
  }, []);

  const totalPages = Math.ceil(projectCards.length / cardsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages - 1) {
      setCurrentPage((prevPage) => prevPage + 1);
    } else {
      setCurrentPage(0); // Loop back to first page
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 0) {
      setCurrentPage((prevPage) => prevPage - 1);
    } else {
      setCurrentPage(totalPages - 1); // Loop to last page
    }
  };

  const handlePageClick = (pageIndex: number) => {
    setCurrentPage(pageIndex);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      handleNextPage();
    }, 15000000); // Automatically change every 120 seconds

    return () => clearInterval(interval); // Clear interval on component unmount
  }, [totalPages]);

  useEffect(() => {
    const titleEl = titleRef.current;
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && titleEl) {
            titleEl.classList.add('fade-in');
          }
        });
      },
      { threshold: 0.5 }
    );

    if (titleEl) {
      observer.observe(titleEl);
    }

    return () => {
      if (titleEl) {
        observer.unobserve(titleEl);
      }
    };
  }, []);

  return (
    <section
      id="projects-section"
      className="projects-section bg-cover bg-center h-screen relative"
      style={{ backgroundImage: `url('/minimalistic-black.svg')` }}
    >
      <h1 className="projects-title-1" ref={titleRef}>
        <span>SOLUTIONS AND</span><br />
        <span>CONTRIBUTIONS</span>
      </h1>
      
      <div className="cards-wrapper-projects fade-slide"> {/* Remove isAnimating state */}
        {projectCards
          .slice(currentPage * cardsPerPage, currentPage * cardsPerPage + cardsPerPage)
          .map((project, index) => (
            <div className="projects-card" key={index}>
              <img 
                src={project.image} 
                alt={`${project.title} Description`} 
                className="projects-description-image"
              />
              <div className="project-description">
                <p>{project.description}</p> {/* This will show the description on hover */}
                <a href={project.link} target="_blank" rel="noopener noreferrer" className="view-on-x">
                  <img src="/x-logo.png" alt="X Logo" className="x-logo" />
                </a>
              </div>
              <h2 className="projects-title">{project.title}</h2>
            </div>
        ))}
      </div>

      <div className="page-indicators">
        {Array.from({ length: totalPages }).map((_, index) => (
          <img
            key={index}
            src={
              index === currentPage
                ? '/test.png'
                : '/inactive-indicator.svg'
            }
            alt={`Page ${index + 1}`}
            onClick={() => handlePageClick(index)}
            className="indicator"
          />
        ))}
      </div>
    </section>
  );
};

export default Projects;
