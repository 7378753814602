import React from 'react';
import { FaGithub } from 'react-icons/fa';
import '../styles/globals.css'; // Import global styles

const Navbar: React.FC = () => {
  return (
    <nav className="sticky top-0 z-50 flex items-center justify-between w-full h-[80px] py-4 px-8 bg-[#0B0B0B] text-white">
      {/* Left Menu */}
      <div className="flex gap-8 navbar-menu">
        <a href="#hero-section" className="text-white text-base no-underline">About Us</a>
        <a href="#services-section" className="text-white text-base no-underline">Services</a>
        <a href="#projects-section" className="text-white text-base no-underline">Contributions</a>
      </div>

      {/* Centered Logo */}
      <div className="absolute left-1/2 transform -translate-x-1/2">
        <img src="/logo-cz.png" alt="CZLabs Logo" className="h-10 w-auto" />
      </div>

      {/* Right Side - Button and Icons */}
      <div className="flex items-center gap-6">
        {/* Separate div for the "Book Now" button */}
        <div className="navbar-button">
          <a href="https://calendly.com/contact-czlabs/30min" target="_blank" rel="noopener noreferrer" className="button-test-1">Book Now</a>
        </div>

        {/* Separate div for the icons */}
        <div className="navbar-icons">
          <a href="https://x.com/czlabs_io" target="_blank" rel="noopener noreferrer">
            <img src="/x-logo.png" alt="X Logo" className="h-6 w-auto" />
          </a>
          <a href="https://github.com/czlabsIO" target="_blank" rel="noopener noreferrer">
            <FaGithub className="text-2xl text-white hover:text-gray-400" />
          </a>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
