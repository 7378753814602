import React, { useEffect, useRef } from 'react';

const AboutUs: React.FC = () => {
  const titleRef = useRef<HTMLHeadingElement | null>(null);
  const buttonTestRef = useRef<HTMLButtonElement | null>(null);
  const contactUsBtnRef = useRef<HTMLButtonElement | null>(null);

  useEffect(() => {
    const titleEl = titleRef.current;
    const buttonTestEl = buttonTestRef.current;
    const contactUsBtnEl = contactUsBtnRef.current;
    const serviceCards = document.querySelectorAll('.service-card');
  
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            // Apply the fade-in class to each observed element
            if (entry.target === titleEl) {
              titleEl.classList.add('fade-in');
            }
            if (entry.target === buttonTestEl) {
              buttonTestEl.classList.add('fade-in');
            }
            if (entry.target === contactUsBtnEl) {
              contactUsBtnEl.classList.add('fade-in');
            }
            // Add fade-in effect to service cards
            if (entry.target.classList.contains('service-card')) {
              entry.target.classList.add('fade-in');
            }
          }
        });
      },
      { threshold: 0.5 }
    );
  
    // Observe the elements
    if (titleEl) observer.observe(titleEl);
    if (buttonTestEl) observer.observe(buttonTestEl);
    if (contactUsBtnEl) observer.observe(contactUsBtnEl);
    serviceCards.forEach(card => observer.observe(card));
  
    return () => {
      // Clean up observer on component unmount
      if (titleEl) observer.unobserve(titleEl);
      if (buttonTestEl) observer.unobserve(buttonTestEl);
      if (contactUsBtnEl) observer.unobserve(contactUsBtnEl);
      serviceCards.forEach(card => observer.unobserve(card));
    };
  }, []);
  

  return (
    <section
      id="services-section"
      className="about-section bg-cover bg-center h-screen"
      style={{ backgroundImage: `url('/3d-rendering-abstract.svg')` }}
    >
      <h1 className="about-title" ref={titleRef}>WHAT CAN WE DO?</h1>
      <img src="/new-asset.svg" alt="" className="corner-image" />

      <div className="cards-wrapper">
        <div className="service-card">
          <h2 className="service-title">Blockchain Development</h2>
          <p className="service-description">
            From smart contract development to decentralized exchanges, our team specializes in 
            building scalable blockchain infrastructure. Ensuring secure, efficient and adaptable 
            solutions for all your needs.
          </p>
          <div className="service-line"></div>
        </div>

        <div className="service-card">
          <h2 className="service-title">AI Integration</h2>
          <p className="service-description">
            <br />
            Integrate AI into your DApps with our advanced AI solutions. You can now leverage 
            machine learning and deep learning to create intelligent applications and enhance 
            user experiences.
          </p>
          <div className="service-line"></div>
        </div>

        <div className="service-card">
          <h2 className="service-title">Consulting & Advisory</h2>
          <p className="service-description">
            <br />
            Looking to start a blockchain or AI project? Our experts provide strategic consulting 
            and end-to-end project management to guide you through every phase of development, 
            ensuring success at every step.
          </p>
          <div className="service-line"></div>
        </div>
      </div>

      <div className="buttons-container">
        <button
          className="button-test"
          ref={buttonTestRef}
          onClick={() => window.open('https://calendly.com/contact-czlabs/30min', '_blank')}
        >
          Book Now
        </button>
        <button
          className="contact-us-btn"
          ref={contactUsBtnRef}
          onClick={() => window.open('https://forms.gle/XYshpL76EoHnXwQi9', '_blank')}
        >
          Contact Us
        </button>
      </div>
    </section>
  );
};

export default AboutUs;
